@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'arial';
  src: url('../../fonts/arial.ttf') format('truetype');
}

@font-face {
  font-family: 'comicbd';
  src: url('../../fonts/comicbd.ttf') format('truetype');
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 20 14.3% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 20 14.3% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 20 14.3% 4.1%;
    --primary: 47.9 95.8% 53.1%;
    --primary-foreground: 26 83.3% 14.1%;
    --secondary: 60 4.8% 95.9%;
    --secondary-foreground: 24 9.8% 10%;
    --muted: 60 4.8% 95.9%;
    --muted-foreground: 25 5.3% 44.7%;
    --accent: 60 4.8% 95.9%;
    --accent-foreground: 24 9.8% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 20 5.9% 90%;
    --input: 20 5.9% 90%;
    --ring: 20 14.3% 4.1%;
    --radius: 1rem;
  }

  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 60 9.1% 97.8%;
    --card: 20 14.3% 4.1%;
    --card-foreground: 60 9.1% 97.8%;
    --popover: 20 14.3% 4.1%;
    --popover-foreground: 60 9.1% 97.8%;
    --primary: 47.9 95.8% 53.1%;
    --primary-foreground: 26 83.3% 14.1%;
    --secondary: 12 6.5% 15.1%;
    --secondary-foreground: 60 9.1% 97.8%;
    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 60 9.1% 97.8%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 12 6.5% 15.1%;
    --input: 12 6.5% 15.1%;
    --ring: 35.5 91.7% 32.9%;
  }
}

@layer base {
  * {
    @apply border-border select-none;
  }

  body {
    @apply bg-background text-foreground;
    -webkit-tap-highlight-color: transparent;
    font-synthesis: none;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-size-adjust: 100%;
  }

  html {
    @apply min-h-screen w-full;
    touch-action: manipulation;
    height: var(--tg-viewport-height, 100%);
  }

  .stroke-black {
    text-shadow:
      2px 0 #000,
      -2px 0 #000,
      0 2px #000,
      0 -2px #000,
      1px 1px #000,
      -1px -1px #000,
      1px -1px #000,
      -1px 1px #000;
  }

  .stroke-darkCyan {
    text-shadow:
      2px 0 #305574,
      -2px 0 #305574,
      0 2px #305574,
      0 -2px #305574,
      1px 1px #305574,
      -1px -1px #305574,
      1px -1px #305574,
      -1px 1px #305574;
  }

  .stroke-darkred {
    text-shadow:
      2px 0 #9e2703,
      -2px 0 #9e2703,
      0 2px #9e2703,
      0 -2px #9e2703,
      1px 1px #9e2703,
      -1px -1px #9e2703,
      1px -1px #9e2703,
      -1px 1px #9e2703;
  }

  .stroke-chocolate {
    text-shadow:
      2px 0 #935015,
      -2px 0 #935015,
      0 2px #935015,
      0 -2px #935015,
      1px 1px #935015,
      -1px -1px #935015,
      1px -1px #935015,
      -1px 1px #935015;
  }
  .stroke-purple {
    text-shadow:
      2px 0 #574393,
      -2px 0 #574393,
      0 2px #574393,
      0 -2px #574393,
      1px 1px #574393,
      -1px -1px #574393,
      1px -1px #574393,
      -1px 1px #574393;
  }
  .stroke-deepRed {
    text-shadow:
      2px 0 #9e2703,
      -2px 0 #9e2703,
      0 2px #9e2703,
      0 -2px #9e2703,
      1px 1px #9e2703,
      -1px -1px #9e2703,
      1px -1px #9e2703,
      -1px 1px #9e2703;
  }
  .stroke-deepBlue {
    text-shadow:
      2px 0 #3C54C3,
      -2px 0 #3C54C3,
      0 2px #3C54C3,
      0 -2px #3C54C3,
      1px 1px #3C54C3,
      -1px -1px #3C54C3,
      1px -1px #3C54C3,
      -1px 1px #3C54C3;
  }
  .stroke-lemon {
    text-shadow:
      2px 0 #fff9ca,
      -2px 0 #fff9ca,
      0 2px #fff9ca,
      0 -2px #fff9ca,
      1px 1px #fff9ca,
      -1px -1px #fff9ca,
      1px -1px #fff9ca,
      -1px 1px #fff9ca;
  }

  .stroke-white {
    text-shadow:
      2px 0 #fff,
      -2px 0 #fff,
      0 2px #fff,
      0 -2px #fff,
      1px 1px #fff,
      -1px -1px #fff,
      1px -1px #fff,
      -1px 1px #fff;
  }

  .stroke-gray {
    text-shadow:
      2px 0 #878B83,
      -2px 0 #878B83,
      0 2px #878B83,
      0 -2px #878B83,
      1px 1px #878B83,
      -1px -1px #878B83,
      1px -1px #878B83,
      -1px 1px #878B83;
  }
}

.ad-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
}


#okxton-widget-root,
#tc-widget-root,
[data-tc-wallets-modal-container="true"] {
  pointer-events: auto !important;
}
